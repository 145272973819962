import { useState } from "react";
import cabrioletIcon from "../../../../../assets/car_icons/cabriolet.svg";
import hatchbackIcon from "../../../../../assets/car_icons/hatchback.svg";
import minivanIcon from "../../../../../assets/car_icons/minivan.svg";
import sedanIcon from "../../../../../assets/car_icons/sedan.svg";
import suvIcon from "../../../../../assets/car_icons/suv.svg";
import vanIcon from "../../../../../assets/car_icons/van.svg";
import wagonIcon from "../../../../../assets/car_icons/wagon.svg";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AlternativeVehicle } from "../../../../../electrify_frontend_common/types";

export function getImageServerWithFallback() {
  return process.env.REACT_APP_ENV === "development" ? "https://staging-app.chargylize.com/api/electrify" : (process.env.REACT_APP_BACKEND_URL as string);
}

export function CarIconSelector(bodyStyle?: string, category?: string) {
  if (bodyStyle === "Cabriolet") return cabrioletIcon;
  if (bodyStyle === "Hatchback") return hatchbackIcon;
  if (bodyStyle === "Small Passenger Van") return minivanIcon;
  if (bodyStyle === "Sedan") return sedanIcon;
  if (bodyStyle === "SUV") return suvIcon;
  if (bodyStyle === "Van") return vanIcon;
  if (bodyStyle === "Station/Estate") return wagonIcon;

  if (category === "N1") return vanIcon;

  return sedanIcon;
}

function FiltersNotSatisfiedBanner({ text }: { text: string }) {
  return <span className="absolute w-fit top-2.5 right-2.5 bg-Grey-tint rounded text-Blueberry-dark-default font-bold text-xs px-1 py-0.5">{text}</span>;
}

export function ContentCardImage({
  vehicleToDisplay,
}: {
  vehicleToDisplay: Partial<AlternativeVehicle> & {
    filtersSatisfied?: boolean;
  };
}) {

  const { t } = useTranslation("results");

  const replaceImgWithError = (e: any) => {
    e.target.onerror = null;
    e.target.src = CarIconSelector(
      vehicleToDisplay.bodyStyle,
      vehicleToDisplay.category
    );
    e.target.className = "object-contain";
  };

  return (
    <div className="flex flex-col justify-center bg-white items-center h-56 w-full p-2 pt-4 relative">
      <div className="flex w-full h-full px-2">
        <BrandInfo
          vehicleBrand={vehicleToDisplay?.make}
          vehicleModel={vehicleToDisplay?.model}
        />
      </div>
      {vehicleToDisplay.filtersSatisfied === false ? (
        <FiltersNotSatisfiedBanner
          text={t("recommendedVehicles.filtersNotSatisfied")}
        />
      ) : null}
      <div className={`flex flex-col items-center justify-center absolute bottom-7`}>
        <img
          src={`${getImageServerWithFallback()}/images/vehicle/ev/medium?evId=${vehicleToDisplay.evId
            }`}
          loading="lazy"
          onError={replaceImgWithError}
          alt="recomended-electric-vehicle"
          className="max-w-52 object-contain"
        />
      </div>
    </div>
  );
}

export function BrandInfo({ vehicleBrand, vehicleModel }: { vehicleBrand?: string | null; vehicleModel?: string | null; }) {
  const [displayBrandImage, setDisplayBrandImage] = useState(true);
  const replaceBrandImgWithEPlaceholder = () => setDisplayBrandImage(false);

  return <div className="flex items-start">
    {displayBrandImage && vehicleBrand ? (
      <img
        className="mr-3 w-10 object-contain aspect-square"
        src={`${getImageServerWithFallback()}/images/logo/oem?name=${vehicleBrand}`}
        loading="lazy"
        onError={replaceBrandImgWithEPlaceholder}
        alt="vehicle-brand"
      />
    ) : (
      <NoBrandImagePlaceHolder />
    )}
    <div>
      <div className="text-xs text-Grey-default">{vehicleBrand}</div>
      <div className="text-sm text-black">{vehicleModel}</div>
    </div>
  </div>;
}

function NoBrandImagePlaceHolder() {
  return <div className="w-10 h-10 rounded-full bg-Grey-light bg-opacity-40 mr-3" />
}


function Metric({ icon, value, unit, tooltipTitle }: { icon: any; value: number; unit: string; tooltipTitle: string; }) {
  return <div className="flex items-center mb-2">
    <Tooltip title={tooltipTitle} arrow placement="top-start">
      {icon}
    </Tooltip>
    <div className="flex items-center ml-4">
      <span
        className={`text-right font-bold text-xs ml-2 text-Black-default`}
      >
        {value?.toLocaleString("de") || "?"} {unit}
      </span>
    </div>
  </div>;
}

export function VehicleMetrics({ vehicleToDisplay, cardColor }: { vehicleToDisplay: Partial<AlternativeVehicle>; cardColor: { icons: any; bigFont: string; smallFont: string }; }) {
  const { t } = useTranslation("results");
  //   const { localeCode, currencySymbol } = useLayout();

  return <div className="flex flex-col h-fit">
    <div className="mt-4">
      <Metric
        icon={cardColor.icons.price}
        value={vehicleToDisplay.listPrice || 0}
        unit={"€"}
        tooltipTitle={t("recommendedVehicles.price")}
      />
    </div>
    <Metric
      icon={cardColor.icons.range}
      value={vehicleToDisplay.realRangeAverage || 0}
      unit={"km"}
      tooltipTitle={t("recommendedVehicles.range")}
    />
    <Metric
      icon={cardColor.icons.consumption}
      value={vehicleToDisplay.energyConsumption || 0}
      unit={"kWh / 100 km"}
      tooltipTitle={t("recommendedVehicles.consumption")}
    />
  </div>;
}
